
.NotePageNav__back-button {
  margin: 45px 20px 25px auto
}
.NotePageNav__back-button:hover {
  background-color: #FFB72F;
}

.NotePageNav__folder-name {
  color: #b5b3b3;
  font-family: var(--custom-font);
  font-size: 40px;
  height: 50vh;
  margin: 0 0 0 auto;
  text-orientation: upright;
  width: 75px;
  writing-mode: vertical-lr;
}
