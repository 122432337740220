
.NoteListNav__list {
  list-style-type: none;
  margin: 130px 0 0;
  padding: 0;
}

.NoteListNav__button-wrapper {
  margin: 0 0 0 auto;
  max-width: 180px;
}

.NoteListNav__add-folder-button {
  margin: 20px auto;
}
.NoteListNav__add-folder-button:hover {
  background-color: #29862e;
  color: white;
}

.NoteListNav__folder-link {
  background-color: #030f1f;
  color: #b5b3b3;
  display: block;
  font-family: var(--custom-font);
  font-size: 20px;
  margin: 0 0 8px auto;
  max-width: 180px;
  padding: 8px 16px;
  text-decoration: none;
}
.NoteListNav__folder-link:hover {
  color: #FFB72F;
  cursor: pointer;
}
.NoteListNav__folder-link.active {
  background-color: #102541;
}

.NoteListNav__num-notes {
  color: #adadad;
  display: block;
  float: right;
  font-size: 10px;
}
